import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import * as React from "react"
import styled from "styled-components"
import UniversalCookies from "universal-cookie"
import SiteLayout from "./SiteLayout"
const universalCookies = new UniversalCookies()

const PageWrapper = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 1400px;
`

function stripSlash(str) {
  return str.replace(/\/+$/, "")
}

const Layout = ({ children }) => {
  const { pathname, search } = useLocation()

  // Split Testing
  //
  // this is primarily here to prevent users from visiting a web page that they aren't bucketed into
  //
  // in order to do this, we have to grab all experiments that a user is bucketed into
  // and see whether or not the requested page is part of an experiment
  // if it is, we redirect to the target path
  //
  // parse cookies and retrieve all split test buckets

  // Paused: March 14, 2022
  // const buckets = universalCookies.get("__we_split") || []
  // const match = buckets.find(b => {
  //   const u = new URL(b.url)
  //   return stripSlash(u.pathname) === stripSlash(pathname)
  // })

  // if (match?.p && stripSlash(match?.p || "") !== stripSlash(pathname)) {
  //   navigate(`${match.p}${search}`, { replace: true })
  //   return null
  // }

  return (
    <SiteLayout>
      <PageWrapper>
        <main>{children}</main>
      </PageWrapper>
    </SiteLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
